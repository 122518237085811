@mixin mobile {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

.form-container {
  background-color: #a7bcff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .form-wrapper {
    background-color: white;
    padding: 1.5rem 3rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    .logo {
      color: #5d5b8d;
      font-weight: bold;
      font-size: 1.5rem;
    }

    .title {
      color: #5d5b8d;
      font-size: 1rem;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      input {
        padding: 1rem;
        border: none;
        border-bottom: 1px solid #a7bcff;
        width: 250px;
        &::placeholder {
          color: rgb(175, 175, 175);
        }
      }
      button {
        background-color: #7b96ec;
        color: white;
        padding: 0.75rem;
        font-weight: bold;
        border: none;
        cursor: pointer;
        border-radius: 10px;
      }

      label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #8da4f1;
        font-size: 0.75rem;
        cursor: pointer;

        img {
          width: 2rem;
        }
      }
    }
    p {
      color: #5d5b8d;
      font-size: 0.75rem;
      margin-top: 0.5rem;
    }
  }
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #a7bcff;

  .container {
    border: 1px solid white;
    border-radius: 10px;
    height: 80%;
    width: 65%;
    display: flex;
    overflow: hidden;
    @include tablet {
      width: 90%;
    }

    .sidebar {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: #3e3c61;
      position: relative;
      .navbar {
        display: flex;
        align-items: center;
        background-color: #2f2d52;
        height: 2.5rem;
        padding: 0.7rem;
        justify-content: space-between;
        color: #ddddf7;
      }
      .logo {
        font-weight: bold;
        @include tablet {
          display: none;
        }
      }
      .user {
        display: flex;
        gap: 0.7rem;

        img {
          background-color: #5d5b8d;
          color: #ddddf7;
          height: 1.5rem;
          width: 1.5rem;
          border-radius: 50%;
          object-fit: cover;
        }

        button {
          background-color: #5d5b8d;
          color: #ddddf7;
          font-size: 0.7rem;
          border: none;
          cursor: pointer;
          @include tablet {
            position: absolute;
            bottom: 0.6rem;
          }
        }
      }

      .search {
        border-bottom: 1px solid gray;
        .search-form {
          padding: 0.7rem;
          input {
            background-color: transparent;
            border: none;
            color: white;
            outline: none;

            &::placeholder {
              color: lightgrey;
            }
          }
        }
      }

      .user-chat {
        display: flex;
        align-items: center;
        padding: 0.7rem;
        gap: 0.7rem;
        color: white;
        cursor: pointer;

        &:hover {
          background-color: #2f2d52;
        }
        img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          object-fit: cover;
        }

        .user-chat-info {
          span {
            font-size: 1rem;
            font-weight: 500;
          }
          p {
            font-size: 0.9rem;
            color: lightgray;
          }
        }
      }

      .chats {
        height: 100%;
        overflow-x: auto;
      }
    }
    .chat {
      flex: 2;

      .chat-info {
        display: flex;
        align-items: center;
        height: 2.5rem;
        background-color: #5d5b8d;
        justify-content: space-between;
        padding: 0.6rem;
        color: lightgray;
      }

      .messages {
        background-color: #ddddf7;
        padding: 0.6rem;
        height: calc(100% - 8.6em);
        overflow: scroll;
        .message {
          display: flex;
          gap: 1.2rem;
          margin-bottom: 1rem;
          .message-info {
            display: flex;
            flex-direction: column;
            font-weight: 300;
            color: gray;
            img {
              width: 2rem;
              height: 2rem;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .message-content {
            max-width: 80%;
            display: flex;
            flex-direction: column;
            gap: 0.6rem;

            p {
              background-color: white;
              padding: 0.6rem 1rem;
              border-radius: 0 0.6rem 0.6rem 0.6rem;
              max-width: max-content;
            }
            img {
              width: 50%;
            }
          }
          &.owner {
            flex-direction: row-reverse;

            .message-content {
              align-items: flex-end;
            }
            p {
              background-color: #8da4f1;
              color: white;
              border-radius: 0.6rem 0 0.6rem 0.6rem;
            }
          }
        }
      }

      .input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        background-color: white;
        padding: 0.6rem;

        input {
          width: 100%;
          border: none;
          outline: none;
          font-size: 1rem;
          color: #2f2d52;

          &::placeholder {
            color: lightgray;
          }
        }
        .send {
          display: flex;
          align-items: center;
          gap: 0.6rem;
          button {
            padding: 0.4rem 0.9rem;
            border: none;
            color: white;
            background-color: #8da4f1;
            cursor: pointer;
          }
        }
      }
    }
  }
}
